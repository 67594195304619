.footer {
    /*background-color:rgb(19, 18, 18) ;*/
    background-color: #036158;
    padding: 50px 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

#main--title {
     /* Center-align text */
    margin-bottom: -10px;
    margin-top: -30px;
   
  }
  .left--f {
    flex: 1 ;
  }
  
  /* Styling the main title (h1) */
  #main--title h1 {
    font-size: 36px; /* Font size */
    margin: 0; /* Remove default margin */
    color: whitesmoke;
  }
  
  #name--NEX {
    font-weight: 400;
  
  }
  
  #name--X {
    color: #8DB600;
    font-size: 45px;
    font-weight: 1000;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    
  }

  .register {
    color: whitesmoke;
    font-size: 16px;
    position: absolute;
  }
  .footer--links {
    display: flex;
    flex-direction: row;
    gap: 70px;
  }
  .footer--links h3 {
    color: whitesmoke;
    padding-top: 40px;
  }

  .footer--links p {
    font-size: 16px;
    padding: 5px 0;
    cursor: pointer;
    
  }

  .right--f {
   padding-top: 90px;
  }

  .newsletter {
    padding-bottom: 15px;
    color: whitesmoke;
  } 

  .subscription--container {
    display: flex;
    gap: 32px;
    background-color: rgb(82, 81, 81);
    padding: 18px 20px;
    border-radius: 20px;

}

.subscription--container > input {
    background-color: transparent;
    border: none;
    outline: none;
    color: rgb(58, 58, 58);
}

::placeholder {
    color:rgb(58, 58, 58);
}

.btn--s {
    background-color: #2bbbad;
    color: whitesmoke;
    border: none;
    border-radius: 5px;
    width: 100px;
    height: 50px;
    font-weight: 800;
    padding: 10px 0;
    cursor: pointer;
    transition: transform 0.3s
}

.btn--s:hover {
  transform: scale(1.1); 
}

.btn--s:active {
  transform: scale(1.2); 
}

.subscription--footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    color: whitesmoke;
    gap: 40px;
}
.subscription--footer p {
    font-size: 15px;
    padding-top: 5px;
}

