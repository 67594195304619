.programs {
  margin-bottom: 0px;
  background-color: rgb(58, 58, 58); 
  padding: 50px 30px;
  
}
.programs--head1, .programs--head4 {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 10px;
  color: transparent;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  -webkit-text-stroke-width: 3px ;
  -webkit-text-stroke-color: whitesmoke;
  padding-left: 30px;
}

.programs--head2 , .programs--head3 {
  font-size: 40px;
  font-weight: 700;
  color: whitesmoke;
  word-spacing: 5px;
  padding-left: 30px;
}
.programs--hr {
  width: 350px;
  height: 8px;
  background-color:#2bbbad; 
  border: none;
  margin-left: 30px;
}

.programm--head--icon {
  color: #2bbbad;
  position: absolute;
  margin-top: -100px;
  margin-left: 900px;
  align-items: center;
  justify-items: center;
  font-size: 100px;
  width: 900;
 

}

.programs--card {
    display: flex; 
    justify-content: center;
    gap: 25px;
    margin-top: 40px;
    padding: 0 20px;
}

.p--card {
  background-color: rgb(82, 81, 81);;
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 40px;
  padding: 20px 20px;
  width: 700px;
  cursor: pointer;
  border-radius: 20px;
 
}

.card--title {
  font-size: 24px;
  color: whitesmoke;
  font-family: cursive;
  
}

.card--desc {
    color: whitesmoke;
    font-size: 18px;
    font-weight: 300;
    

}

.card--footer {
  height: 30px;
  width: 110px;
  color: grey;
  align-items: center;
  font-size: 15px;
  border: none;
  border-radius: 10px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
