/* Styling the main header container */
.main--header {
  background-color: #e0e6e0; /* Whitish background color */
  color: #333; /* Grey text color */
  padding: 20px; /* Padding around the content */
  display: flex; /* Display children in a row */
  justify-content: space-between; /* Space between children */
  align-items: center; /* Center vertically */
  height: fit-content;
  
}

/* Styling the title section */
.main--title {
  text-align: center; /* Center-align text */
  margin-bottom: -10px;
  margin-top: -30px;
  cursor: pointer;
 
}

/* Styling the main title (h1) */
.main--title h1 {
  font-size: 36px; /* Font size */
  margin: 0; /* Remove default margin */
}

.name--NEX {
  font-weight: 400;

}

.name--X {
  color: #8DB600;
  font-size: 45px;
  font-weight: 1000;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  
}

/* Styling the subtitle (h4) */
.main--title h4 {
  font-size: 10px; /* Font size */
  margin: 0; /* Remove default margin */
  font-weight: 300;
  line-height: 8px;
  letter-spacing: 1.5px;
}

/* Styling the navigation menu */
.main--nav {
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove default padding */
  display: flex; /* Display list items in a row */
}

/* Styling the navigation menu items (list items) */
.main--nav li {
  margin-right: 70px; /* Spacing between items */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Add pointer cursor on hover */
  text-decoration: none; /* Remove default text underline */
  position: relative; /* Position relative for pseudo-element */
  color: #333;
}

.main--nav li:hover {
  color: #333;
}

.main--nav li::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #2bbbad;
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.3s ease;
}

.main--nav li:hover::before {
  transform: scaleX(1);
}
