.plans {
    background-color: rgb(58, 58, 58);  
    padding: 50px 80px;
}

.plans--head1, .plans--head4 {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 10px;
    color: transparent;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    -webkit-text-stroke-width: 3px ;
    -webkit-text-stroke-color: whitesmoke;
}

.plans--head2 , .plans--head3 {
    font-size: 40px;
    font-weight: 700;
    color: whitesmoke;
    word-spacing: 5px;
}
.plans--hr {
    width: 320px;
    height: 8px;
    background-color:#2bbbad; 
    border: none;
}

.plans--Card {
    display: flex; 
    justify-content: center;
    gap: 30px;
    margin-top: 40px;
    padding-bottom: 40px;
    cursor: pointer;
}

.card {
    background-color: rgb(82, 81, 81);;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px ;
    border-radius: 20px;
    width: 350px;
}

.card:hover p,
.card:hover .list--icon {
  color: whitesmoke;
}



.title {
    color: whitesmoke;
    font-family: cursive;
}

.price {
    color: whitesmoke;
    font-family: cursive;
}

#list {
    align-items: start;
    padding-left: -20px; 
    margin-left: -120px;
    
}

.list--icon {
    color: #2bbbad;
    padding-right: 5px;
}
.desc1, .desc2, .desc3, .desc4{
    font-size: 15px;
    align-items: start;
    list-style: none;
    padding-left: -90px;
    padding-top: 5px;
}



.button {
    width: 250px;
    height: 30px;
    margin: 10px;
    font-weight: 700;
    border-radius: 5px;
    border: none;
    margin-top: 40px;
}

