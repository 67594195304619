.Reasons {
    background-color:rgb(58, 58, 58) ;
    padding: 50px 80px;
    display: flex;
    gap: 50px;
}

.left-r  img{
    width: 600px;
    height: 500px;
    
}
section {
	width: 100%;
	line-height: 0;
	position: relative;
   
}
section img {
	width: 100%;
    border-radius: 20px;
}
section article {
	position: absolute;
	top: 0;
	width: 100%;
	background: whitesmoke;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 10px;
	mix-blend-mode: lighten;
    
}
section div {
	background: black;
	height: 18.6vw;
    
}
section div:nth-of-type(2) {
	grid-column: 2 / 4;
}


   

.right--head--1 {
    color: #2bbbad;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    letter-spacing: 3px;

}

.right--head--why {
    font-size: 70px;
    color: transparent;
    font-weight: 900;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    -webkit-text-stroke-width: 3px ;
    -webkit-text-stroke-color: whitesmoke;
}
.right--head--2 {
    font-size: 30px;
    color: whitesmoke;
    margin-top: -15px;

}


.h5--reasons {
    margin-top: -10px;
    
    
}

li {
    color: whitesmoke;
    font-size: 18px;
    list-style: none;
    font-family: cursive;
    padding-left: -50px;
    
   
    
}

.reason--mark {
    font-size: 25px;
    color: #2bbbad;
    padding-right: 10px;
    
}

.partner--head {
    color: grey;
    margin-top: 20px;
    font-size: 40px;
}


.puma, .fila, .adidas{
    width: 120px;
    height: 120px;
    padding-left: 10px;
}