.media {
    padding: 10px 90px;
    background-color: #036158;
}

.media--hr {
    background-color: silver;
    width: 100%;
    border: none;
    height: 1px;
}

.media--footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 70px 0;
}
.media--footer p {
    font-size: 16px;
    font-weight: 400;
}
.media--icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 30px;
    color: whitesmoke;
    gap: 30px;
    cursor: pointer;
}
.footer--footer {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 40px;
    text-align: center;
    font-weight: 400;
}

